// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/AveriaSerifLibre-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/AveriaSerifLibre-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-gray) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root,
body,
html {
  --bg-gray: #333333;
  --bg-blue: #ebdbb2;
  --navbar-bg: #d5c4a1;
  --button-main: #546f80;
  --button-hover: #fabd2f;
  --button-click: #2e3c46;
  --info-text-color: #795f51;
  --blue-text-color: #795f51;
  --table-bg: #546f80;
  --table-border-color: #2e3c46;
  --icon-hover: #f1f0e1;
}

@font-face {
  font-family: "Averia Serif Libre";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-weight: 400;
}

@font-face {
  font-family: "Averia Serif Libre";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,2CAA2C;AAC7C;;AAEA;EACE;aACW;AACb;;AAEA;;;EAGE,kBAAkB;EAClB,kBAAkB;EAClB,oBAAoB;EACpB,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,0BAA0B;EAC1B,0BAA0B;EAC1B,mBAAmB;EACnB,6BAA6B;EAC7B,qBAAqB;AACvB;;AAEA;EACE,iCAAiC;EACjC,4CAAuD;EACvD,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;EACjC,4CAAoD;EACpD,gBAAgB;AAClB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: var(--bg-gray) !important;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n#root,\nbody,\nhtml {\n  --bg-gray: #333333;\n  --bg-blue: #ebdbb2;\n  --navbar-bg: #d5c4a1;\n  --button-main: #546f80;\n  --button-hover: #fabd2f;\n  --button-click: #2e3c46;\n  --info-text-color: #795f51;\n  --blue-text-color: #795f51;\n  --table-bg: #546f80;\n  --table-border-color: #2e3c46;\n  --icon-hover: #f1f0e1;\n}\n\n@font-face {\n  font-family: \"Averia Serif Libre\";\n  src: url(\"./assets/fonts/AveriaSerifLibre-Regular.ttf\");\n  font-weight: 400;\n}\n\n@font-face {\n  font-family: \"Averia Serif Libre\";\n  src: url(\"./assets/fonts/AveriaSerifLibre-Bold.ttf\");\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
